import Mock from "../mock";

const database = {
  information: {
    name: 'Leonardo Mendes',
    aboutContent: "I am a passionate full-stack web developer with over 10 years of global experience creating innovative solutions for companies worldwide. My expertise spans developing E-commerce Platforms, Websites, Softwares and custom Apps that drive results. I thrive on staying at the forefront of technology, continuously seeking best practices to excel in what I truly love—building impactful digital experiences. Known for being an enthusiastic team player, a relentless learner, and adaptable to fast-paced environments, I consistently deliver high-quality work under pressure. My goal is to grow both professionally and personally, contributing my skills to projects that make a difference while embracing every opportunity for learning and collaboration.",
    age: 35,
    phone: '+55 (11) 94531-7704',
    nationality: 'Brazilian',
    language: 'Portuguese, English',
    email: '',
    address: 'São Paulo - SP, Brazil',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://www.facebook.com/leo.89mendes',
      twitter: '',
      pinterest: '',
      instagram: 'https://instagram.com/leodevmendes',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/leonardo-mendes-dreamsintocode/',
      dribbble: '',
      github: ''
    },
    brandImage: '/images/ProfilePic.png',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/resume.pdf'
  },
  services: [
    {
      title: "Database",
      icon: 'database',
      details: "I can development a database strategy that can adapt to changing business needs."
    },
    {
      title: "E-commerce",
      icon: 'code',
      details: "I can bring your business into the web using Magento, Shopify, WordPress, Laravel and more."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "I can create any type of mobile application using React Native, Flutter and Cordoba."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "JAVASCRIPT",
      value: 90
    },
    {
      title: "JQUERY",
      value: 85
    },
    {
      title: "VUEJS",
      value: 80
    },
    {
      title: "PHP",
      value: 85
    },
    {
      title: "NODEJS",
      value: 85
    },
    {
      title: "LARAVEL",
      value: 85
    },
    {
      title: "AWS",
      value: 40
    },
    {
      title: "NEXT",
      value: 50
    },
    {
      title: "SASS",
      value: 95
    },
    {
      title: "TAILWIND",
      value: 95
    },
    {
      title: "DOCKER",
      value: 60
    },
    {
      title: "MYSQL",
      value: 85
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Peaceful Parenting",
      subtitle: "Alchemising Your Parenting Journey",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: [
        "/images/portfolio-image-3-lg.jpg"
      ],
      url: 'https://peacefulparenting.com.au'
    },
    
    {
      id: 2,
      title: "Study Net",
      subtitle: "Education & Migration.",
      imageUrl: "/images/portfolio-image-2.jpg",
      largeImageUrl: [
        "/images/portfolio-image-2-lg.jpg"
      ],
      url: 'https://studynet.com'
    },
    {
      id: 3,
      title: "Avalon Painting",
      subtitle: "Painting Company",
      imageUrl: "/images/portfolio-avalon-sm.jpg",
      largeImageUrl: ["/images/portfolio-avalon-lg.jpg"],
      url: 'https://avalon-painting.com.au'
    },
    {
      id: 4,
      title: "Icon Fitness",
      subtitle: "Health & Fitness",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg"
      ],
      url: 'https://iconfitness.com'
    },
    {
      id: 5,
      title: "Game Quadras",
      subtitle: "Over 42 years in the industrie.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://gamequadras.com.br'
    },
    {
      id: 6,
      title: "Amor&Limões",
      subtitle: "Bra and Panties Company",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: [
        "/images/portfolio-image-5-lg.jpg"
      ],
      url: 'https://amorelimoes.com.br/'
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2020 - 2024",
        position: "Full-Stack Web Developer",
        company: "Building 8/building8.com.br",
        details: "Manage development projects throughout the entire lifecycle: requirements analysis, system design, development, and integration into customer environments. Work closely with the product owner, customers, and other teams to strengthen our standard product. Implement front-end components with Vue.js framework. Translate the client’s technical requirements into code. Test and update web applications so that they can be used on all web browsers. Designing and maintaining MYSQL databases. Developing testing and validation processes using PHP Unit."
      },
      {
        id: 2,
        year: "2015 - 2019",
        position: "Web Developer / Part-Time",
        company: "Study Net/studynet.com.au",
        details: "Development of the front-end and back-end of corporate website. Analyzing and scoping of requirements to provide development estimates, technical solutions, and features. Provide ideas and technical approaches for development. Supporting other members of the development team."
      },
      {
        id: 3,
        year: "2011 - 2012",
        position: "Full-Stack Web Developer",
        company: "LabON/labon.com.br",
        details: "Development of the front-end and back-end of corporate websites and digital projects across a range of industries and technologies, testing code and implementing improvements and fixes. Contribute to analysis, design, and development of web-based solutions, develop and maintain technical documentation. Produce and maintain quality code to company standards. Work closely with the product manager to ensure that development practices align with the business and end customers needs."
      },
      {
        id: 4,
        year: "Jan/11 - Oct/11",
        position: "Full-Stack Web Developer",
        company: "V-TEX e-commerce Cloud Software/vtex.com.br",
        details: "Responsible for set up all the new clients into the platform. Design and implementation of continuous integration and development. Ensure that non-functional requirements such as security, performance, maintainability, scalability, usability, and reliability are being considered. Design user interactions to provide a simple and intuitive user experience."
      },
      {
        id: 5,
        year: "2008 - 2010",
        position: "Software Developer",
        company: "LightComm/axiros.com",
        details: "Ensuring the entire stack is designed and built for security, reliability, speed and scalability, converse and interact professionally with clients as and when required. Build and maintain a scalable software application. Develop the 3G dialer interface using Action Script 2.0 with Delphi communication on the back end."
      },
      {
        id: 6,
        year: "May/08 - Sep/08",
        position: "Full-Stack Web Developer",
        company: "Editora Cadiz/editoracadiz.com.br",
        details: "Web developer with responsibility of publication all the magazine on website: Responsible design and maintenance. Database designing, Development and Administration. Users interacting. Programming in ASP, MSQL, JavaScript and ActionScript 2.0"
      },
      {
        id: 7,
        year: "Jan/08 - May/08",
        position: "Web Designer",
        company: "Super Exclusivo/superexclusivo.com.br",
        details: "My first formal job. Design content for websites and email campaigns. Update website imagery, copy and content. Develop new design ideas for our websites and pro-actively develop new concepts and tools to communicate brand personality online. Design, build and deliver HTML email campaigns (EDMs)"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2020 - 60Hours",
        graduation: "ReactJS",
        university: "Udemy/udemy.com",
        details: "The Complete Guide."
      },
      {
        id: 2,
        year: "Jan/13 - Dec/13",
        graduation: "General Intensive English",
        university: "Elsis/Sydney, Australia",
        details: "General English (Speaking, Reading, Write and Read)."
      },
      {
        id: 3,
        year: "2010 - 2012",
        graduation: "Information System",
        university: "SENAC/SP, Brazil",
        details: "All about the Information System."
      },
      {
        id: 4,
        year: "Jan/06 - Jun/06",
        graduation: "Web Design",
        university: "SOS Computadores/SP, Brazil",
        details: "How to developer website using DreamWeaver, PhotoShop, HTML, CSS, FireWorks and CorelDraw."
      },
      {
        id: 5,
        year: "Aug/05 - Aug/05",
        graduation: "PhotoShop",
        university: "SOS Computadores/SP, Brazil",
        details: "Learn how to use PhotoShop, how to create images with the best tools that PhotoShop could provide to you."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+55 (11) 94531-7704'],
    emailAddress: ['leonardo@dreamsintocodes.com'],
    address: "Vitória - ES, Brazil"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});